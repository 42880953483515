import React from 'react'
import { Helmet } from 'react-helmet'

const HrefLang = (data) => {
    return(
        <Helmet>
            { data.data.map( (version, index) =>
                <link key={`language-switcher-item-${index}`} rel="alternate" hreflang="lang" href={`https://www.vitaly-malkin.com/${version.lang}${version.uid}`} />
            )}
        </Helmet>
    )
}

export default HrefLang;
