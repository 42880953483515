import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Box, Button, Flex, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import linkResolver from '../utils/linkResolver'
import TagsFilter from './TagsFilter';

const Roll = ({ items, slugPrefix, smallThumbnail }) => {
  const [filteredItems, setFilteredItems] = useState(items);

  const allTags = items.map(({ node: { data: { tag }} }) => tag?.text);
  const uniqueTags = [...new Set(allTags)].filter(Boolean);

  const handleTagClick = (newTag) => {
    const newItems = newTag ? items.filter(({ node: { data: { tag: { text }}} }) => text === newTag) : items;

    setFilteredItems(newItems);
  };

  return (
    <Box
      p=".5rem"
      maxW="1030px"
      mx="auto"
      py="2rem"
      px={{ base:'1rem', lg:0 }}
    >
      {/* <pre>
        { JSON.stringify( items, null, 4 ) }
      </pre> */}
      { items ?
      <Stack spacing="5rem">
        {slugPrefix === 'blog' && <TagsFilter tags={uniqueTags} onClick={handleTagClick} />}
        {
          filteredItems.map(item =>
            <Box
              // display={ item.node.data.is_visible ? 'block' : 'none' } as="article" key={ item.node.uid }
            >
              {/* <pre>
                { JSON.stringify( item, null, 4 ) }
              </pre> */}
              <Grid templateColumns={{ base:'100%', lg: smallThumbnail ? "20% calc(80% - 3rem)" : "30% calc(70% - 3rem)" }} gridGap="3rem">
                <Box>
                  <Image
                    // size="200px"
                    w={{ base:'260px', lg:'auto' }}
                    mx='auto'
                    loading="lazy"
                    src={
                      item.node.data['cover'] && item.node.data['cover']['fixed'] ?
                        item.node.data['cover']['fixed']['src']
                          : item.node.data['featured_image'] && item.node.data['featured_image']['fixed']
                            ? item.node.data['featured_image']['fixed']['src']
                              : 'null'}
                    alt={item.node.data['cover'] ? item.node.data['cover']['alt'] : item.node.data['featured_image'] ? item.node.data['featured_image']['alt'] : 'null'}
                  />
                </Box>
                <Flex alignItems="center">
                  <Stack spacing="1.5rem">
                    <Heading as="h2" fontSize='28px'>
                      {/* {RichText.asText()} */}
                      { item.node.data.title.text }
                      {/* { JSON.stringify( item.node.data.title ) } */}
                    </Heading>
                    <Text fontFamily="Open Sans" fontSize='18px'>
                      {item.node.data['intro'] ?
                        RichText.asText(item.node.data['intro']['raw'])
                      : null}
                    </Text>
                <Flex justify="flex-end" flexWrap="wrap">
                  {/* <pre>
                    { JSON.stringify( item.node , null, 3)}
                  </pre> */}
                  <Button
                    to={`${linkResolver(item.node)}`}
                    boxShadow='sm'
                    py='1.5rem'
                    as={GatsbyLink}
                    backgroundColor="brand.orange"
                    border="solid 1px"
                    borderColor="transparent"
                    borderRadius="1px"
                    color="white"
                    textTransform="uppercase"
                    px="4rem"
                    fontFamily="Open Sans"
                    fontWeight="700"
                    w={{ base:'100%', lg:'auto' }}
                    _hover={{
                      border:"solid 1px",
                      borderColor:"brand.orange",
                      color:"brand.orange",
                      backgroundColor:"brand.cream"
                    }}
                  >
                    <FormattedMessage id="main.discover" />
                  </Button>
                </Flex>
                </Stack>
                </Flex>
              </Grid>
            </Box>
          )
        }
      </Stack>
      :
        <Box>
          <Text>No contents in this section for the moment</Text>
        </Box>
      }
    </Box>
  )
}

export default Roll
