import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'
import Roll from '../components/Roll'
import HrefLang from '../utils/HrefLang'
import { RichText } from 'prismic-reactjs'
import { Box, Heading } from '@chakra-ui/react'
import css from '../components/slices/wysiwyg.module.scss'
import NewsletterFormArchive from '../components/NewsletterFormArchive'

const Archives = ({ data, pageContext }) => {
  const pageContent = data.prismic.allArchives.edges[0]
  if (!pageContent) return null
  const page            = data.page
  const books           = data.books.edges
  const posts           = data.posts.edges
  const projects        = data.projects.edges
  const commitments     = data.commitments.edges
  const topMenuContent  = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  // console.log( 'data.archive', data )

  const filterBooks = ( books ) => {
    let filteredBooks = []
    for (let index = 0; index < books.edges.length; index++) {
      const book = books.edges[index];
      if( book.node.data.is_visible || book.node.data.is_visible === null){
        filteredBooks.push( book )
      }
    }
    return filteredBooks
  }
  // console.log( 'pageContent', pageContent )
  if (page) {
    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={
          pageContent.node._meta
          // { lang:page.lang, alternateLanguage: page.alternate_languages }
        }
        lang={ pageContext.langIso } 
        color="light"
      >
        {/* <pre>
          { JSON.stringify( pageContent , null , 2 )}
        </pre> */}
        <HrefLang data={ page.alternate_languages } />
        { page.data.intro ? 
          <Box
            maxW='1030px'
            mx='auto'
            p={{ base:'1rem', lg: 0 }}
            my={{ base:4, lg:20 }}
            px={{ base:'1rem', lg:'4rem' }}
          >
            <Heading
              display='none'
              as='h1'
              fontFamily='Vesper Libre'
              fontSize={{ base:'38px', lg:'42px' }}
              fontFamily='Vesper Libre'

            >
              { page.data.title.text }
            </Heading>
            <Box
              fontSize={{ base:16, lg:18 }}
              className={css.wysiwyg}
              my={{ base:2, lg:4}}
            >
              <RichText render={ page.data.intro.raw || []} />
            </Box>
          </Box>
        : null}

        {/* <RichText render={slice.primary.text || []} /> */}

        {/* <pre>
          { JSON.stringify( projects, null, 5)}
        </pre> */}
        <Box
          maxW='1030px'
          mx='auto'
          px={{ base:0, lg:'4rem' }}
        >
          { page.data.content_type === 'Books' ?
            <>
              <Box
                bg='gray.50'
                border='solid 1px'
                borderColor={{ base:'gray.50', lg:'gray.200' }}
                borderRadius='sm'
                p={{ base: 4, lg: 6 }}
                maxW='1030px'
                mx='auto'
              >
                <NewsletterFormArchive />
              </Box>
              <Roll items={ books } slugPrefix={'livres'} smallThumbnail={ true } />
            </>
            : page.data.content_type === 'Posts' ?
              <Roll items={posts} slugPrefix={'blog'} />
              : page.data.content_type === 'Commitments' ?
                <Roll items={commitments} slugPrefix={'blog'} />
                : page.data.content_type === 'Projects' ?
                  <Roll items={projects} slugPrefix={'blog'} />
                  : null
          }
        </Box>
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query archivesQuery($uid: String, $lang: String) {

  page: prismicArchive( uid: { eq: $uid } ) {
    id
    uid
    prismicId
    lang
    alternate_languages{
      link_type
      id
    }
    data {
      title { text }
      intro { raw }
      content_type
    }
  }

  posts: allPrismicPost(
    filter:{
      lang: { eq: $lang}
      data: { is_visible: { eq: true }}
  	}
    sort: { fields: first_publication_date, order: DESC }
  ){
    edges{
      node{
        id
        uid
        prismicId
        lang
        type
        data{
          is_visible
          title{ text raw }
          intro{ text raw }
          tag{ text }
          featured_image{ alt fixed{ src }}
        }
      }
    }
  }

  books: allPrismicBook(
    filter:{ lang: { eq: $lang}}
    sort: { fields: first_publication_date, order: DESC }
  ){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text raw }
          intro{ text raw }
          cover{ alt fixed{ src }}
        }
      }
    }
  }

  projects: allPrismicProject(
    filter:{ lang: { eq: $lang}}
    sort: { fields: first_publication_date, order: DESC }
  ){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text raw }
          intro{ text raw }
          featured_image{ alt fixed{ src }}
        }
      }
    }
  }

  commitments: allPrismicCommitment(
    filter:{ lang: { eq: $lang}}
    sort: { fields: first_publication_date, order: DESC }
  ){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text raw }
          intro{ text raw }
          featured_image{ alt fixed{ src }}
        }
      }
    }
  }

  prismic {
    allArchives(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          content_type
        }
      }
    }
    allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          is_visible
          title
          intro
          cover
        }
      }
    }
    allPosts (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          title
          intro
          featured_image
          is_visible
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default Archives
