import React, { useState } from 'react'
import { Box, Text } from '@chakra-ui/react';

const TagsFilter = ({ tags, onClick }) => {
  const [selectedTag, setSelectedTag] = useState();
  const getStyles = (tag) => selectedTag === tag ? {
    color: "white",
    backgroundColor: "brand.orange",
  } : {
    color: "brand.orange",
    backgroundColor: "white",
  }

  const handleClick = (tag) => () => {
    setSelectedTag((prevTag) => {
      const newTag = prevTag === tag ? undefined : tag;
        onClick(newTag);

        return newTag;
    });
  }

  return (
    <Box display="flex" flexWrap='wrap'>
      {
        tags.map((tag) => (
          <Text
            key={tag}
            marginRight={5}
            marginBottom={5}
            userSelect="none"
            cursor="pointer"
            border="solid 1px"
            borderRadius="4px"
            padding={2}
            className="tag"
            color="brand.orange"
            onClick={handleClick(tag)}
            {...getStyles(tag)}
          >
            {`#${tag}`}
          </Text>
        ))
      }
    </Box>
  )
}

export default TagsFilter
